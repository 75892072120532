import {cloneDeep, omit} from "lodash";

export const ABOUT = {"name": "About Us", "value": "/about"};
export const BOOKING = {"name": "Book An Appointment", "value": "/booking"};
export const LOCATION = {"name": "Location", "value": "/location"};
export const SERVICES = {"name": "Services Offered", "value": "/services"};
export const TESTIMONIAL = {"name": "Customer Testimonials", "value": "/testimonial"};

export const ACUPUNCTURE = {
    "name": "Acupuncture", 
    "value": "/service/acupuncture",
    "imgName": "acup",
    "desc": <>
    {"Traditional Chinese medicine (TCM) is thousands of years old. Its basic concept is that a vital force of life, called Qi, surges through the body. Any imbalance to Qi can cause disease and illness. TCM treatment seeks to restore this balance through treatment specific to the individual. Treatment to regain balance may involve acupuncture, moxibustion, cupping, tui-na (Chinese massage) and herbal remedies."}
    <br /><br />
    {"TCM can effectively improve a wide variety of conditions such as women's health including PMS, PCOS, endometriosis, menopause, fertility, pregnancy, pre- & post-natal care, and cervical ripening. Mental health includes PTSD vital alignment, anxiety, depression and insomnia. Other special interests include radio- & chemo-therapy support, medical microneedling, facial rejuvenation, NAET allergy elimination, pediatric NO-needling acupuncture, chronic fatigue syndrome, MVA, other physical injuries, fibromyalgia and arthritis."}
    <br /><br />
    {"The 90-minute initial consultation includes TCM diagnosis, acupuncture treatment and heat lamp if it is appropriate."}
    </>
}
export const CUPPING = {
    "name": "Cupping", 
    "value": "/service/cupping",
    "imgName": "cup",
    "desc": "Cupping is a type of alternative therapy that involves placing cups on the skin to create suction, which improves blood circulation & the flow of energy in the body and facilitates healing. The 30-minute initial treatment includes an initial FULL health consultation and is perfect for someone who does not have the time for a full acupuncture treatment but is looking for a quick, relaxing and effective therapeutic result."
}
export const EAR_SEED = {
    "name": "Ear Seeds", 
    "value": "/service/ear-seed",
    "imgName": "ear",
    "desc": "Ear seeds are a form of auriculotherapy. It is like a small bandage -about the size of a fingernail - that contains a ripe seed from the Vaccaria plant. Acupuncturist places each ear seed carefully on your ear in specific acupressure points that correspond with the conditions you’re experiencing. The ear seed is like an acupuncture treatment, which is used to stimulate a channel to help the patient’s condition without needing to puncture the skin. The 30-minute initial FULL health consultation including TCM diagnosis and ear-seed treatment."
}
export const FACIAL_REJUV = {
    "name": "Facial Rejuvenation Acupuncture", 
    "value": "/service/facial-acup",
    "imgName": "faceAcup",
    "desc": "Facial Rejuvenation Acupuncture is a treatment method with very thin, single use specialty needles on the face to help reduce the visible signs of aging, improve skin texture & skin tone, increase elasticity, reduction of wrinkles and overall rejuvenation. The 90-minute treatment includes a full health assessment & skin analyst, a facial cleansing according to skin type, a facial & body acupuncture optimizing treatment,  a complete facial lymphatic drainage, cupping & massage (aka. Gua-sha) with genuine jade healing stone & rose oil or organic rosehip oil, therapeutic & relaxing. A revitalizing moisturizer application completes this super relaxing and therapeutic facial experience. 100% All-Natural Chinese herbal mask available with extra cost (consult with practitioner)"
}
export const HERBAL_MASK = {
    "name": "Facial Herbal Mask, Cupping & Massage", 
    "value": "/service/facial-mask",
    "imgName": "facial",
    "desc": "This 60-minute non-needling treatment includes a full health assessment & skin analyst, facial cleansing according to skin type, a complimentary 100% natural Chinese herbal mask for smooth complexion, tightening & whitening, a complete facial lymphatic drainage, cupping & massage (aka. Gua-sha) with genuine jade healing stone & rose oil or organic rosehip oil, therapeutic & relaxing. A revitalizing moisturizer application completes this super relaxing and therapeutic facial experience."
}
export const MICRONEEDLE = {
    "name": "Medical Microneedling", 
    "value": "/service/microneedle",
    "imgName": "microNeedle",
    "desc": "Microneedling is a skin rejuvenation treatment that naturally helps trigger collagen and elastin synthesis within the skin. The procedure uses a device with multiple needles that penetrate the skin’s surface. These tiny holes cause a micro injury to the skin, which stimulates collagen & elastin production & also aids the skin’s ability to regenerate and renew. This approach also leads to rapid healing and minimal downtime. This treatment includes diagnosis, PLUS Face & body optimizing ACUPUNCTURE treatment, numbing cream (optional), serum application, Microneedling of the full face or selected area(s), facial cooling & calming treatment with certified organic aloe vera gel infused with lavender, and facial moisturizer to revitalize. Various areas are available with different costs (please consult with our practitioner prior to booking)"
}
export const HERBAL_CONSULT = {
    "name": "Herbal Consultation", 
    "value": "/service/herbal-consult",
    "imgName": "herb",
    "desc": <>
    {"Traditional Chinese medicine (TCM) is thousands of years old. Its basic concept is that a vital force of life, called Qi, surges through the body. Any imbalance to Qi can cause disease and illness. TCM treatment seeks to restore this balance through treatment specific to the individual. Treatment to regain balance may involve acupuncture, moxibustion, cupping, tui-na (Chinese massage) and herbal remedies."}
    <br/><br/>
    {"This herbal consultation is ONLY for follow-up patients who have an initial complete health assessment done and is NOT available as an initial appointment. Cost of herbal remedies is extra."}
    </>
}
export const REIKI = {
    "name": "Reiki Healing", 
    "value": "/service/reiki",
    "imgName": "reiki",
    "desc": "Reiki is an energy healing technique that promotes relaxation, stress reduction and symptom relief to improve overall health and well-being. Mikao Usui developed reiki in the early 1900s, deriving the term from the Japanese words rei, meaning “universal,” and ki, which refers to the vital life force energy that flows through all living things. Reiki practitioners act as a conduit between you and the source of the universal life force energy. The energy flows through the practitioner’s hand to deliver energy to your body, improving the flow and balance of your energy to support healing. Reiki aids in healing by helping people become energetically balanced — physically, emotionally, mentally and spiritually. Our Reiki Master-leveled practitioner is passionate to help guide & support you on your healing journey. "
}
export const ANGEL_CARD = {
    "name": "Reiki Healing & Angel Card Reading", 
    "value": "/service/angel-cards",
    "imgName": "angel",
    "desc": "Reiki is an energy healing technique that promotes relaxation, stress reduction and symptom relief to improve overall health and well-being. Mikao Usui developed reiki in the early 1900s, deriving the term from the Japanese words rei, meaning “universal,” and ki, which refers to the vital life force energy that flows through all living things. Reiki practitioners act as a conduit between you and the source of the universal life force energy. The energy flows through the practitioner’s hand to deliver energy to your body, improving the flow and balance of your energy to support healing. Reiki aids in healing by helping people become energetically balanced — physically, emotionally, mentally and spiritually. Our Reiki Master-leveled practitioner is passionate to help guide & support you on your healing journey. In this 75-minute appointment, different Angel cards are used to connect you with the messages from your own guardian angels."
}
export const COMPRESSION_STOCKINGS = {
    "name": "Compression Stockings",
    "value": "/service/compression-stockings",
    "imgName": "compressionStockings",
    "desc": ""
}
export const FREE_CONSULT = {
    "name": "Free Consultation", 
    "value": "/service/free-consult",
    "imgName": "",
    "desc": "Acupuncture and traditional Chinese medicine (TCM) can effectively treat a wide variety of conditions such as women's health issues, digestive disorders, weight loss, depression, anxiety, insomnia, headaches, physical inquires, arthritis, immunity, skin conditions, chemo- & radio-therapy support, chronic fatigue syndrome, allergies, post-stroke recovery, etc. Book a FREE 15-minute consultation with our practitioner to learn more about your health."
}

export const INFERTILITY = {
    "name": "Fertility",
    "value": "/service/fertility",
    "imgName": "infertility",
    "desc": ""
}

export const ALLERGY = {
    "name": "Allergy Elimination",
    "value": "/service/allergy-elimination",
    "imgName": "allergy",
    "desc": ""
}

export const ALL_PATHS = {
    ABOUT,
    BOOKING,
    LOCATION,
    SERVICES,
    TESTIMONIAL,
    ACUPUNCTURE,
    CUPPING,
    EAR_SEED,
    FACIAL_REJUV,
    HERBAL_MASK,
    MICRONEEDLE,
    HERBAL_CONSULT,
    REIKI,
    ANGEL_CARD,
    COMPRESSION_STOCKINGS,
    INFERTILITY,
    FREE_CONSULT,
    ALLERGY,
};

export const ALL_VISIBLE_PATHS = omit(
    cloneDeep(ALL_PATHS), []);