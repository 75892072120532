import { Typography } from "@material-ui/core";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import acup from "../images/acup.jpg";
import compressionStockings from  "../images/compression-stockings.jpg";
import cup from "../images/cup.jpg";
import ear from "../images/ear.jpg";
import faceAcup from "../images/face-acup.jpg";
import faceCup from "../images/face-cup.jpg";
import facial from "../images/facial.jpg";
import guaSha from "../images/gua-sha.jpg";
import herb from "../images/herb.jpg";
import infertility from "../images/fertility.jpg"
import microNeedle from "../images/micro-needle.jpg";
import allergy from "../images/allergy.jpg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2e1667",
    },
    secondary: {
      main: "#357DDE",
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
    ],
    h4: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: '2rem',
    },
    h5: {
      fontWeight: 100,
      lineHeight: '2rem',
    },
  },
});

const styles = makeStyles({
  wrapper: {
    width: "65%",
    margin: "auto",
    textAlign: "center",
  },
  bigSpace: {
    marginTop: "5rem",
  },
  littleSpace: {
    marginTop: "2.5rem",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

const LandingPage = () => {
  const classes = styles();
  const imgList = [acup, allergy, cup, herb, ear, faceAcup, microNeedle, facial, faceCup, guaSha, compressionStockings, infertility];

  return (
    <div className="Landing">
        <ThemeProvider theme={theme}>
            <div className={`${classes.wrapper}`}>
                <Typography variant="h4" color="secondary">
                    Embrace your health and beauty the natural way, with Chinese medicine.
                </Typography>
                <Typography variant="h5" className={classes.littleSpace} color="primary">
                  Being a licensed pharmacist in Canada since 1989, as well as a doctor of acupuncture and herbalist, Dr Florence Kwok will help and guide you in your journey to health and well-being with a well-integrated approach.
                </Typography>
            </div>

            <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={6100}
            >
              {imgList.map(image => {
                return <img alt="" src={image}/>;
              })}
            </Carousel>
        </ThemeProvider>
    </div>
  );
}

export default LandingPage;
